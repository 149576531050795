<template>
  <div>
    <v-form v-model="formValid">
      <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
      <v-row no-gutters class="py-1">
        <v-col>
          <v-text-field
            v-model="name"
            :rules="[max255CharRule, presenceRule]"
            dense
            filled
            label="Nome"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-1">
        <v-col>
          <v-text-field
            v-model="description"
            :rules="[max255CharRule, presenceRule]"
            dense
            filled
            label="Descrizione"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-1">
        <v-col>
          <ServiceTypeAutocomplete
            v-model="serviceTypes"
            return-object
            multiple
            @input="updateServiceTypes"
          ></ServiceTypeAutocomplete>
        </v-col>
      </v-row>
      <v-row 
        v-if="!!viewBar.active"
        no-gutters 
        class="py-1"
      >
        <v-col>
          <v-checkbox v-model="isBar">
            <template v-slot:label>
              <div>
                Reparto Bar
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row-reverse">
          <v-btn 
            color="primary" dark @click="openOrderFamily">
            Cambia Ordine Famiglie
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
     <StandardDialog 
        v-model="orderFamily" 
        :dialog-height="null" 
        title="Ordina servizi" 
        close-button-text="Chiudi"
      >
        <OrderFamilyForm
        :id="id"
        :bus="bus">

        </OrderFamilyForm>
        <template v-slot:footer-actions>
            <v-btn color="error" text @click="closeOrder"
              >Chiudi</v-btn
            >
            <v-btn
              color="default"
              text
              @click="changeOrder"
            >Conferma</v-btn
          >
          </template>
      </StandardDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import serviceTypesCategoryForm from '@/services/serviceTypesCategories/serviceTypesCategory.form.js'
import ServiceTypeAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue'
import OrderFamilyForm from '@/components/areas/registries/serviceTypesCategories/OrderFamilyForm.vue';
import StandardDialog from "@/components/common/StandardDialog.vue";

export default {
  name: "GeneralFormTab",
  components: { 
    ServiceTypeAutocomplete,
    StandardDialog,
    OrderFamilyForm
  },
  data: function() {
    return {
      name: undefined,
      description: undefined,
      loadingServiceTypesCategory: false,
      serviceTypes: undefined,
      formValid: false,
      orderFamily: false,
      id: undefined,
      isBar: false,
      viewBar: undefined,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      presenceCategory: (v) => {
        return !!v && v.length > 0
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: function() {
    this.handleObjectChanges(serviceTypesCategoryForm.serviceTypesCategory) 

    this.$emit('update:valid', this.formValid)
    if(this.bus) {
      this.bus.$emit('update:valid', this.formValid)
    }

    serviceTypesCategoryForm.setValid(this.formValid)

    var self = this
    serviceTypesCategoryForm.on('update', function(data) {
      self.handleObjectChanges(data.serviceTypesCategory)
      self.id = data.serviceTypesCategory.id
    })
  },
  methods: {
    fields() {
      return ['name', 'description', 'serviceTypes', 'isBar']
    },
    handleObjectChanges(serviceTypesCategory) {
      const fields = this.fields()
      const newValKeys = Object.keys(serviceTypesCategory)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && serviceTypesCategory[field] != this[field]) {
          this[field] = serviceTypesCategory[field]
        }
      }

      if(!!serviceTypesCategory.serviceTypes) {
        for(let i = 0; i < serviceTypesCategory.serviceTypes.length; i+=1) {
          serviceTypesCategory.serviceTypes[i].id = serviceTypesCategory.serviceTypes[i].id.toString()
        }
      }
    },
    updateServiceTypes(newServiceTypes) {
      serviceTypesCategoryForm.updateField('serviceTypes', newServiceTypes)
    },
    openOrderFamily(){
      this.orderFamily = true
    },
    closeOrder(){
      this.orderFamily = false
      this.bus.$emit('closeOrder')
    },
    changeOrder(){
      this.orderFamily = false
      this.bus.$emit('changeOrder')
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      serviceTypesCategoryForm.setValid(newVal)
    },
    name(newVal) {
      serviceTypesCategoryForm.updateField('name', newVal)
    },
    description(newVal) {
      serviceTypesCategoryForm.updateField('description', newVal)
    },
    isBar(newVal){
      serviceTypesCategoryForm.updateField('isBar', newVal)
    }
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  },
};
</script>