<template>
  <FullScreenDialog
    v-model="serviceTypesCategoryDialog"
    title="Nuovo reparto"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in serviceTypesCategoryTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in serviceTypesCategoryTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/registries/serviceTypesCategories/form/GeneralFormTab.vue"
import serviceTypesCategoriesService from '@/services/serviceTypesCategories/serviceTypesCategories.service.js'
import serviceTypesCategoryForm from '@/services/serviceTypesCategories/serviceTypesCategory.form.js'

export default {
  name: "ServiceTypesCategoriesRegistryNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      serviceTypesCategoryDialog: this.openDialog,
      serviceTypesCategoryTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      serviceTypesCategory: undefined,
      originalUrl: window.location.pathname,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    serviceTypesCategoryForm.resetServiceTypesCategory()
    serviceTypesCategoryForm.on('update', function(data) {
      self.serviceTypesCategory = data.serviceTypesCategory
    })

    serviceTypesCategoryForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.serviceTypesCategoryTabs.length; i++) {
        if(this.serviceTypesCategoryTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      if(!this.serviceTypesCategory.isBar) 
        this.serviceTypesCategory.isBar = false

      if (!!this.serviceTypesCategory.serviceTypes) {
        this.serviceTypesCategory.serviceTypes = this.serviceTypesCategory.serviceTypes.map((serviceType) => {
          delete serviceType.services
          return serviceType
        })
      }

      this.loading = true
      serviceTypesCategoriesService.create(this.serviceTypesCategory).then((result) => {
        this.loading = false
        this.$router.push({path: this.pathToGoBack})
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.serviceTypesCategoryDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.serviceTypesCategoryTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>