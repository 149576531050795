import eventsHandler from '@/services/common/eventsHandler.js'

class serviceTypesCategoryForm extends eventsHandler {
  constructor() {
    super()
    this._serviceTypesCategory = {}
    this._valid = false
  }

  get serviceTypesCategory() {
    const serviceTypesCategory = {
      ...this._serviceTypesCategory
    }
    return serviceTypesCategory
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._serviceTypesCategory.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetServiceTypesCategory() {
    this._serviceTypesCategory = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {serviceTypesCategory: {}})
  }

  updateField(key, value) {
    this._serviceTypesCategory[key] = value
    this._executeCallbacksOf('update', {serviceTypesCategory: this._serviceTypesCategory})
    this._executeCallbacksOf('update-' + key, {key: this._serviceTypesCategory[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(serviceTypesCategory) {
    this._serviceTypesCategory = serviceTypesCategory
    this._executeCallbacksOf('update', {serviceTypesCategory: this._serviceTypesCategory})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new serviceTypesCategoryForm()